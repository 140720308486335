import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useGlobalStateContext } from "../../common/Context/app";
import Layout from '../../common/components/template/Layout';
import { PageProp } from '../../common/types';
import PagePartRenderer from '../PagePartRender';

const Index: React.FC<PageProp> = ({ data, pageContext }) => {
  const { setSiteConfig } = useGlobalStateContext();
  const pageParts = data?.pageData?.pageParts;

  useEffect(() => {
    setSiteConfig && pageContext && setSiteConfig({
      payload: pageContext,
      type: 'SET'
    })
  }, [pageContext]);

  return (
    <Layout
    metaDescription={pageContext?.metadata?.page_description || ''} 
    metaTitle={pageContext?.metadata?.page_title || ''} 
    meta={[]} 
    lang={'en'}
     >
      {pageParts?.map?.((part, i) => <PagePartRenderer part={ part } key={i} id={i}  />)}
    </Layout>
  )
}

export const query = graphql`
  query GalleryPage($id: String!) {
    pageData: sanityGallery(_id: {eq: $id}) {
      pageParts {
        ... on SanityCardWide {
          _key
          _type
          navanchor
          title
          image: _rawImage(resolveReferences: {maxDepth: 8})
          body: _rawBody(resolveReferences: {maxDepth: 3})
          cardStyle
          cardHeading
          button:_rawButton(resolveReferences: {maxDepth: 5})
        }

        ... on SanityHero {
          _key
          _type
          image: _rawImage(resolveReferences: {maxDepth: 10})
          navanchor
          partStyle
          title
        }

        ... on SanityPictureGallery {
          _key
          _type
          pictures:  _rawPictures(resolveReferences: {maxDepth: 5})
          display
          navanchor
          zoom
        }

        ... on SanityReadNextPart {
          _key
          _type
          _rawBody(resolveReferences: {maxDepth: 5})
          _rawPages(resolveReferences: {maxDepth: 5})
          navanchor
          partStyle
          title
        }

        ... on SanitySimpleContentBlock {
          _key
          _type
          _rawBody(resolveReferences: {maxDepth: 5})
          _rawImage(resolveReferences: {maxDepth: 5})
          _rawButton(resolveReferences: {maxDepth: 5})
          image_placement
          partStyle
          navanchor
          title
        }

      }
    }
  }
`

export default Index;
